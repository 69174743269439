import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import "./Style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  SMARTSHEETS_ROUTE,
  LOGIN_ROUTE,
  PRIVACY_POLICY_ROUTE,
  CONTACT_US_ROUTE,
  PAGE_NOT_FOUND_ROUTE,
  COMPANIES_ROUTE,
  SANICO_USA_EMPLOYEE_FORM,
  PACE_UNIVERSITY_EMPLOYEE_FORM,
  NYHS_EMPLOYEE_FORM,
  FORM_SETTING_ROUTE,
} from "./Constant/Index";
import Smartsheets from "./pages/smartSheets/Smartsheets";
import Login from "./Components/Login/Login";
import ContactUs from "./pages/contactUs/ContactUs";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import Navbar from "./Components/Common/Navbar/Navbar";
import { IMAGES } from "./assets/images/images";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useState } from "react";
import Companies from "./pages/companies/Companies";
import SnicoUsaForm from "./Components/SnicoUsaForm/SnicoUsaForm";
import PaceUniversityForm from "./Components/PaceUniversityForm/PaceUniversityForm";
import NyhsForm from "./Components/NYHForm/NYHSForm";
import FormSetting from "./pages/FormSetting/FormSetting";

const App = () => {
  console.log("hello I am here")
  const location = useLocation();
  const [isLoginRoute, setIsLoginRoute] = useState(
    location.pathname === LOGIN_ROUTE
  );

  useEffect(() => {
    setIsLoginRoute(location.pathname === LOGIN_ROUTE);
  }, [location]);

  const token = localStorage.getItem("token");
  const userRole = localStorage.getItem("type");

  const publicRoutes = [
    LOGIN_ROUTE,
    PRIVACY_POLICY_ROUTE,
    SANICO_USA_EMPLOYEE_FORM,
    PACE_UNIVERSITY_EMPLOYEE_FORM,
    NYHS_EMPLOYEE_FORM,
    CONTACT_US_ROUTE,
  ];
  useEffect(() => {
    // Exclude public routes from the redirect to login

    if (!token && !publicRoutes.includes(location.pathname)) {
      window.history.pushState(null, "", LOGIN_ROUTE);
    }
  }, [location.pathname, token]);

  return (
    <main className="d-flex w-100">
      {!isLoginRoute && !publicRoutes.includes(location.pathname) && (
        <section className="pageLeftSide">
          <Navbar
            userRole={userRole}
            logo={IMAGES.safeLogo}
            navlink5="Projects"
            navlink6="Sites"
            navlink7="Form Setting"
          />
        </section>
      )}

      <section
        className={
          isLoginRoute
            ? "pageRightSide full-width"
            : publicRoutes.includes(location.pathname)
            ? "pageRightSide full-width"
            : "pageRightSide"
        }
      >
        <Routes>
          <Route path={PRIVACY_POLICY_ROUTE} element={<PrivacyPolicy />} />
          <Route path={CONTACT_US_ROUTE} element={<ContactUs />} />
          <Route path={SANICO_USA_EMPLOYEE_FORM} element={<SnicoUsaForm />} />
          <Route
            path={PACE_UNIVERSITY_EMPLOYEE_FORM}
            element={<PaceUniversityForm />}
          />
          <Route path={NYHS_EMPLOYEE_FORM} element={<NyhsForm />} />

          {!token && (
            <>
              <Route path={LOGIN_ROUTE} element={<Login />} />
              <Route path="/*" element={<Navigate to="/login" replace />} />
            </>
          )}

          {token && userRole === "admin" && (
            <>
              <Route
                path="/"
                element={<Navigate to={SMARTSHEETS_ROUTE} replace />}
              />
              <Route path={SMARTSHEETS_ROUTE} element={<Smartsheets />} />
              <Route path={COMPANIES_ROUTE} element={<Companies />} />
              <Route path={FORM_SETTING_ROUTE} element={<FormSetting />} />
            </>
          )}
          {/* Redirect to login if not authenticated */}
          <Route
            path="*"
            element={<Navigate to={PAGE_NOT_FOUND_ROUTE} replace />}
          />
          <Route
            path={PAGE_NOT_FOUND_ROUTE}
            element={<PageNotFound pageName="/smartsheets" />}
          />
        </Routes>
      </section>
    </main>
  );
};

export default App;
