import React, { useEffect, useRef, useState } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Components/Common/Loader/Loader";
import { copyToClipboard } from "../../utils";
import classes from "./Index.module.css";
import { IMAGES } from "../../assets/images/images";

const SmartsheetdataTable = (props) => {
  const {
    companies,
    handleConnectSmartSheet,
    updatedSite,
    setUpdatedSite,
    handleDisableSmartSheet,
    handleGenerateForm,
    handleGetSmartSheetForm,
    getSmartSheetFormLink,
  } = props;
  const [activeDropdownItemId, setActiveDropdownItemId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [skip, setSkip] = useState(10);
  const [sites, setSites] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdownItemId(null);
        setIsDropdownOpen(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (updatedSite) {
      const updatedSiteIndex = sites.findIndex(
        (site) => site.id.toString() === updatedSite.siteId
      );
      if (updatedSiteIndex !== -1) {
        const updatedSites = [...sites];
        updatedSites.splice(updatedSiteIndex, 1, {
          ...sites[updatedSiteIndex],
          smartSheetwebhook: { enabled: updatedSite.enabled },
        });
        setSites(updatedSites);
      }
      setUpdatedSite(null);
    }
  }, [updatedSite]);

  const getIndex = (index) => {
    if (currentPage === 1) {
      return index + 1;
    } else {
      return index + 1 + (currentPage - 1) * skip;
    }
  };

  const columns = [
    {
      name: <b>Id</b>,
      selector: (_, index) => getIndex(index),
      sortable: false,
      width: "50px",
    },
    {
      name: <b>Project Name</b>,
      selector: (item) => (
        <span title={item.companyName}>{item.companyName}</span>
      ),
      sortable: true,
    },
    {
      name: <b>Site Name</b>,
      selector: (item) => <span title={item.siteName}>{item.siteName}</span>,
      sortable: true,
    },
    {
      name: <b>Sheet Id</b>,
      selector: (item) => <span title={item.sheetId}>{item.sheetId}</span>,
      width: "150px",
    },
    {
      name: <b>Webhooks</b>,
      selector: (item) => item.siteName,
      width: "300px",
      cell: (item) => (
        <>
          <button
            className={`btn btn-sm ${
              item.smartSheetwebhook
                ? "btn-event-history-filled pointer-events-none"
                : "btn-event-history"
            }`}
            onClick={() =>
              handleConnectSmartSheet({
                siteId: item.id.toString(),
                companyId: item.companyId,
              })
            }
          >
            {item.hasOwnProperty("smartSheetwebhook")
              ? "Smartsheet Connected"
              : "Connect Smartsheet"}
          </button>
          {item.hasOwnProperty("smartSheetwebhook") && (
            <button
              className="ms-1 btn btn-sm btn-event-history-danger"
              onClick={() =>
                handleDisableSmartSheet({
                  siteId: item.id.toString(),
                  companyId: item.companyId,
                  sheetId: item.smartSheetwebhook?.id,
                  status: !item.smartSheetwebhook.enabled,
                })
              }
            >
              {item.smartSheetwebhook?.enabled ? "Disable" : "Enable"}
            </button>
          )}
        </>
      ),
      sortable: false,
    },
    {
      name: <b>Action</b>,
      cell: (item) => (
        <>
          {item.formDetails ? (
            <>
              <button
                onClick={() =>
                  handleGetSmartSheetForm({
                    id: item.sheetId,
                  })
                }
                className={`btn btn-sm w-100 ${
                  item.formDetails
                    ? "btn-event-history-filled text-white"
                    : "btn-event-history"
                }`}
              >
                Get SmartSheet Form
              </button>
            </>
          ) : (
            <button
              className={`btn btn-sm btn-outline-success w-100`}
              onClick={() =>
                handleGenerateForm({
                  siteId: item.id.toString(),
                  companyId: item.companyId,
                  shouldUpdate: false,
                })
              }
            >
              Generate Form
            </button>
          )}
        </>
      ),
      sortable: false,
    },

    {
      name: <b></b>,
      selector: (item) => {
        const isDropdownActive = activeDropdownItemId === item._id;
        return (
          <>
            <div>
              {isDropdownActive && (
                <div
                  className={`${classes.dropdown} d-flex flex-column`}
                  ref={dropdownRef}
                >
                  {item.formDetails && (
                    <div
                      className={`cursor-pointer ${classes.dropDownItem} `}
                      onClick={() => {
                        handleGenerateForm({
                          siteId: item.id.toString(),
                          companyId: item.companyId,
                          shouldUpdate: true,
                          formDetails: item.formDetails,
                        });
                      }}
                    >
                      Edit Form Details
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className={`d-flex ${classes.dropdownToggle}`}
              onClick={() => {
                if (isDropdownActive) {
                  setActiveDropdownItemId(null);
                  setIsDropdownOpen(false);
                } else {
                  setActiveDropdownItemId(item._id);
                  setIsDropdownOpen(true);
                }
              }}
            >
              <div
                className={`${
                  isDropdownActive ? classes.kababMenuActive : classes.kababMenu
                }`}
              >
                <img src={IMAGES.kababMenu} alt="" />
              </div>
            </div>
          </>
        );
      },
      width: "50px",
    },
  ];

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handlePerRowsChange = (s) => {
    setSkip(s);
  };

  return (
    <div className="data-table-wrapper">
      <div id="userDataTable" className={`${classes.userDataTable}`}>
        <DataTable
          columns={columns}
          data={companies}
          pagination
          highlightOnHover
          onChangeRowsPerPage={(s) => handlePerRowsChange(s)}
          onChangePage={(pageNumber) => onPageChange(pageNumber)}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default SmartsheetdataTable;
