import safeSiteLogo from "../images/SafeSitelogo.png";
import signOut from "../images/signout.svg";
import dashboardlink from "../images/dasgboardlink.svg";
import userlink from "../images/userlink.svg";
import profile from "../images/profile.jpg";
import email from "../images/email.svg";
import key from "../images/key.svg";
import exclamation from "../images/exclamation.svg";
import singleShape from "../images//single-shape.svg";
import horizontalShape from "../images/horizontal-two-shape.svg";
import verticalShape from "../images/vertical-two-shape.svg";
import editIcon from "../images/pencil-icon.svg";
import deleteIcon from "../images/trash-icon.svg";
import FilterI from "../images/FilterI.svg";
import dashboardgre from "../images/dashboardgre.svg";
import userblack from "../images/userblack.svg";
import crossHam from "../images/cross-ham.svg";
import hamBurger from "../images/hamburger-menu.svg";
import kababMenu from "../images/kabab-menu.svg";
import policyIconBlack from "../images/policy-icon-black.svg";
import policyIconGreen from "../images/policy-icon-green.svg";
import employeeGreen from "../images/employee-color.svg";
import employeeBlk from "../images/employee-blk.svg";
import EventsProfile from "../images/events-profile.png";
import avatarImage from "../images/avatar.png";
import employeeImage from "../images/employees.svg";
import employeeGreenImage from "../images/employee-green.svg";
import LogoForm from "../images/logo-form.jpeg";
import loggedInImage from "../images/logged-in.png";
import incClientIdImage from "../images/incClientId.png";
import invClientStep1Image from "../images/invClientNextStep.png";
import invMainPage from "../images/invMainPage.png";
import invClientStepFourImage from "../images/invClientStepFour.png";
import InvUserNameImage from "../images/InvUserName.png";
import userInfoImage from "../images/userName-1.png";
import invSelectComapnyImage from "../images/invSelectCompany.png";
import invSelectPopupImage from "../images/popupInfo.png";
import invClientSecretCopyImage from "../images/invClientSecretCopyImage.png";
import invCreateApplicationImage from "../images/create-application.png";
import invFindingAppImage from "../images/invFindingApp.png";
import invClientSecretStepOne from "../images/inClientSecretStep1.png";
import SafeSiteFormLogo from "../images/SafeSiteFormLogo.png";
export const IMAGES = {
  safeLogo: safeSiteLogo,
  FormLogo: SafeSiteFormLogo,
  signOut: signOut,
  dashboardlink: dashboardlink,
  userlink: userlink,
  profile: profile,
  emailIcon: email,
  keyIcon: key,
  singleShape: singleShape,
  horizontalShape: horizontalShape,
  verticalShape: verticalShape,
  editIcon: editIcon,
  deleteIcon: deleteIcon,
  exclamationIcon: exclamation,
  FilterI: FilterI,
  dashboardgre: dashboardgre,
  userblack: userblack,
  crossHam: crossHam,
  hamBurger: hamBurger,
  kababMenu: kababMenu,
  policyIconBlack: policyIconBlack,
  policyIconGreen,
  employeeGreen,
  employeeBlk,
  EventsProfile,
  avatarImage,
  employeeImage,
  employeeGreenImage,
  LogoForm,
  userInfoImage,
  loggedInImage,
  incClientIdImage,
  invClientStep1Image,
  invMainPage,
  invClientStepFourImage,
  InvUserNameImage,
  invSelectComapnyImage,
  invSelectPopupImage,
  invClientSecretCopyImage,
  invCreateApplicationImage,
  invFindingAppImage,
  invClientSecretStepOne,
};
