import { DASHBOARD_CHART_ROWS } from "../Constant/Index";

export const getCurrentBaseUrl = () => {
  return `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? ":" + window.location.port : ""
  }`;
};

export const getChartStepSize = (maxVal = 0) =>
  maxVal > DASHBOARD_CHART_ROWS ? Math.ceil(maxVal / DASHBOARD_CHART_ROWS) : 1;

export const getChartMaxRows = (maxVal = 0) =>
  maxVal > DASHBOARD_CHART_ROWS * 2 ? maxVal + 1 : DASHBOARD_CHART_ROWS * 2;

export const copyToClipboard = async (linkToCopy = "") => {
  return await navigator.clipboard.writeText(linkToCopy);
};
