import axios from "axios";
import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { SlCalender } from "react-icons/sl";
import Swal from "sweetalert2";
import { IMAGES } from "../../assets/images/images";
import {
  ADD_ROW_TO_SMARTSHEET,
  GET_FORM_SETTING_LIST,
  GET_SMART_SHEET_FORM_DETAILS,
} from "../../services/URL";
import InputFields from "../Common/InputField/InputFields";
import Loader from "../Common/Loader/Loader";
import RadioBtn from "../Common/RadioBtn/RadioBtn";
import styles from "./SnicoUsaForm.module.css";
import { useLocation, useSearchParams } from "react-router-dom";
import { format } from "date-fns";

const initialRadioState = {
  orientation: "",
  orientationError: "",
  jobRole: "",
  jobRoleError: "",
  ethnicity: "",
  ethnicityError: "",
  veteran: "",
  veteranError: "",
  employer: "",
  employerError: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_SELECTION":
      return {
        ...state,
        [action.name]: action.value,
        [`${action.name}Error`]: "",
      };
    case "RESET":
      return initialRadioState;
    case "SET_ERROR":
      return {
        ...state,
        [`${action.name}Error`]: action.error,
      };
    default:
      return state;
  }
};
const SnicoUsaForm = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const ID = urlParams.get("id");
  // const specficSheetId1 = "3518628599123844";

  const sheetIds = ["47623726432422899666", "351862232859912384477"];
  // const sheetIds = ["4762372644228996", "3518628599123844"];

  // console.log(ID);

  // const id = searchParams.get('id');

  // Parse the URL search parameters back into an object
  const searchParams = new URLSearchParams(location.search);
  const searchParamsObj = Object.fromEntries(searchParams.entries());

  // State to manage the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    reset,
    register,
    watch,
  } = useForm({
    mode: "onChange",
  });
  // The system might allow facility codes from 0 to 255.
  // Therefore, the range of values for cardNumber  is [0, 64999].
  // const MIN_MAX_VALUES = {
  //   facilityCode: {
  //     min: 0,
  //     max: 255,
  //   },
  //   cardNumber: {
  //     min: 0,
  //     max: 64999,
  //   },
  // };
  const INITIAL_FORM_DATA = {
    firstName: "",
    lastName: "",
    jobTitle: "",
    phoneNumber: "",
    email: "",
    // contactList: "",
    homeaddress: "",
    streetAddress: "",
    unit: "",
    city: "",
    state: "",
    zipCode: "",
    emergencyContact: "",
    emergencyContactPhone: "",
    // hardhatNumber: "",
    trade: "",
    employer: "",
    // firstCardNumber: "",
    // lastCardNumber: "",
    gender: "",
    ethnicity: "",
    veteran: "",
    orientation: "",
    fireWatchCard: "",
    profileImageUpload: "",
    weldingCertificate: "",
    weldingCertificateUpload: "",
    SSTCardUpload: "",
    FireWatchCertificateUpload: "",
    ScaffoldCertificateUpload: "",
    ScissorLiftCertificateUpload: "",
    RiggerCertificateUpload: "",
    // orientationDate: null,
    // orientation1YearExpire: null,
    sstIssueDate: null,
    sstExpireDate: null,
    fireWatchExpire: null,
    biometricConsent: false,
    sendCopy: false,
  };

  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  //   const selectedJobRadio = watch("job");
  const [selectedJob, setSelectedJob] = useState("");
  const [smartSheetData, setSmartSheetData] = useState({});
  const [isGender, setIsGender] = useState("");
  const [genderError, setGenderError] = useState("");
  const [radioSelection, setRadioSelection] = useReducer(
    reducer,
    initialRadioState
  );
  const [isIdentifyAs, setIsIdentifyAs] = useState("");
  const [identifyasError, setidentifyasError] = useState("");
  const [isMinority, setIsMinority] = useState("");
  const [minorityError, setminorityError] = useState("");
  const [isFireWatch, setIsFireWatch] = useState("");
  const [fireWatchError, setfireWatchError] = useState("");
  const [isWelderCertified, setIsWelderCertified] = useState("");
  const [welderCertifiedError, setwelderCertifiedError] = useState("");
  const [showWelderDatePicker, setShowWelderDatePicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isWeldingCertificate, setIsWeldingCertificate] = useState(null);
  const [isSSTCardUpload, setIsSSTCardUpload] = useState(null);
  const [isFireWatchCertificateUpload, setIsFireWatchCertificateUpload] =
    useState(null);
  const [isScaffoldCertificateUpload, setIsScaffoldCertificateUpload] =
    useState(null);
  const [isScissorLiftCertificateUpload, setIsScissorLiftCertificateUpload] =
    useState(null);
  const [isRiggerCertificateUpload, setIsRiggerCertificateUpload] =
    useState(null);
  const [biometricConsent, setBiometricConsent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImageSizeError, setprofileImageSizeError] = useState("");
  const [weldingCertificateError, setweldingCertificateError] = useState("");
  const [SSTCardSizeError, setSSTCardSizeError] = useState("");
  const [FireWatchCertificateSizeError, setFireWatchCertificateSizeError] =
    useState("");
  const [ScaffoldCertificateSizeError, setScaffoldCertificateSizeError] =
    useState("");
  const [ScissorLiftCertificateSizeError, setScissorLiftCertificateSizeError] =
    useState("");
  const [RiggerCertificateSizeError, setRiggerCertificateSizeError] =
    useState("");
  const [employer, setEmployer] = useState([
    "Visitor",
    "Sanico USA",
    "United Hoisting & Scaffolding Corp",
    "Turner",
  ]);
  const [ethnicity, setEthnicity] = useState([
    "White",
    "Hispanic",
    "Black",
    "Asian",
    "American Indian",
    "Pacific Islander",
    "Other",
  ]);
  const [gender, setGender] = useState(["Male", "Female", "Other"]);
  const [orientation, setOrientation] = useState(["Straight", "LGBTQIA+"]);
  const [jobRole, setJobRole] = useState([
    "Apprentice",
    "Journeyman",
    "Foreman",
    "Superintendent",
    "Project Management",
    "Engineering",
    "Marketing",
    "Other",
  ]);
  const [veteran, setVeteran] = useState(["Yes", "No"]);
  const [minorityoptions, setMinority] = useState(["Yes", "No"]);
  const [identifyasoptions, setIdentifyAs] = useState([
    "Male",
    "Female",
    "Other",
  ]);

  const getFormDeatils = async () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    try {
      setIsLoading(true);
      const body = {
        sheetId: searchParamsObj.id,
      };
      const response = await axios.post(GET_SMART_SHEET_FORM_DETAILS, body, {
        headers,
      });
      const {
        formDetails,
        sheetId,
        columns,
        siteName,
        biometricEnabled,
        id,
        companyId,
      } = response.data.data;
      setSmartSheetData({
        sheetId,
        companyId,
        siteId: id,
        sheetColumns: columns,
        siteName,
        biometricEnabled,
        title: formDetails?.title,
        address: formDetails?.address,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // const getFormSettingList = async () => {
  //   try {
  //     setIsLoading(true);
  //     const body = {
  //       siteId: smartSheetData.siteId?.toString(),
  //       companyId: smartSheetData.companyId,
  //       isForm: true,
  //     };
  //     // console.log("body", body);
  //     const apiResponse = await axios.post(GET_FORM_SETTING_LIST, body);
  //     let response = apiResponse.data?.data[0]?.employerRoles;
  //     if (response?.length > 0) {
  //       setEmployer(response);
  //       // console.log("response", response);
  //     }

  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // };
  useEffect(() => {
    const loadData = async () => {
      try {
        await getFormDeatils();
      } catch (error) {
        console.error("An error occurred while loading data:", error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
      if (Object.keys(smartSheetData).length === 0) return;
  
      // getFormSettingList();
  
      // Load picklist type data from sheetColumns
      const picklistFields = smartSheetData.sheetColumns.filter(
        (field) => field.type === "PICKLIST"
      );
  
      const getPicklistFieldsData = picklistFields.reduce((acc, field) => {
        const normalizedTitle = field.title.replace(/\s+/g, "").toLowerCase();
        acc[normalizedTitle] = field.options || [];
        return acc;
      }, {});
  
      const updatePicklistState = (data) => {
        const stateMap = {
          ethnicity: setEthnicity,
          gender: setGender,
          orientation: setOrientation,
          jobrole: setJobRole,
          employer: setEmployer,
          veteran: setVeteran,
          minority: setMinority,
          identifyas: setIdentifyAs,
        };
    
        Object.keys(stateMap).forEach((key) => {
          if (data[key]) stateMap[key](data[key]);
        });
      };
    
      // Fetch picklist data and update the relevant state
      const picklistFieldsData = getPicklistFieldsData;
      updatePicklistState(picklistFieldsData);
    
  }, [smartSheetData]);
  
  

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;
    const formattedDay = String(day).padStart(2, "0");
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDate = `${formattedMonth}/${formattedDay}/${year}`;
    return formattedDate;
  }

  const handleBiometricChange = (event) => {
    setBiometricConsent(event.target.checked);
  };

  const weldingCertificateOptions = ["Yes", "No"];

  const handleWeldingCertificationChange = (certificationStatus) => {
    setIsWelderCertified(certificationStatus);
    setShowWelderDatePicker(certificationStatus === "Yes");
  };

  // const jobTitles = [
  //   "Apprentice",
  //   "Journeyman",
  //   "Foreman",
  //   "Superintendent",
  //   "Project Management",
  //   "Engineering",
  //   "Marketing",
  //   "Others",
  // ];
  // const gender = ["Male", "Female", "Other"];
  // const orientation = ["Straight", "LGBTQIA+"];
  // const jobRole = [
  //   "Apprentice",
  //   "Journeyman",
  //   "Foreman",
  //   "Superintendent",
  //   "Project Management",
  //   "Engineering",
  //   "Marketing",
  //   "Other",
  // ];

  //for ONBoarding Template for column ethnicity

  // const veteran = ["Yes", "No"];

  // const employer = [
  //   "Visitor",
  //   "Sanico USA",
  //   "United Hoisting & Scaffolding Corp",
  //   "Turner",
  // ];
  // const minorityoptions = ["Yes", "No"];

  // const identifyasoptions = ["Male", "Female", "Other"];

  const fireWatchCard = ["Yes", "No"];

  // console.log("smartSheetData", smartSheetData.sheetColumns );

  const handleRadioChange = (jobTitle) => {
    setSelectedJob(jobTitle);
  };
  const handleGenderRadioChange = (gender) => {
    setIsGender(gender);
    setGenderError("");
  };
  const handleOptionsRadioChange = (option) => {
    const { name, value } = option.target;
    // console.log("name", name, "value", value);

    // console.log("employer", radioSelection.employer);

    // console.log("option", option);

    setRadioSelection({ type: "SET_SELECTION", name, value });
  };

  const handleIdentifyAsRadioChange = (identifyas) => {
    setIsIdentifyAs(identifyas);
    setidentifyasError("");
  };
  const handleMinorityChange = (minority) => {
    setIsMinority(minority);
    setminorityError("");
  };
  const handleFireWatchRadioChange = (title) => {
    setIsFireWatch(title);
    if (title.toLowerCase() === "yes") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
    }
  };

  const resetForm = () => {
    reset();
    setSelectedJob(null);
    setIsGender(null);
    setIsIdentifyAs(null);
    setIsMinority(null);
    setIsFireWatch(null);
    setIsWelderCertified(null);
    setSelectedFile(null);
    setIsWeldingCertificate(null);
    setIsSSTCardUpload(null);
    setIsFireWatchCertificateUpload(null);
    setIsScaffoldCertificateUpload(null);
    setIsScissorLiftCertificateUpload(null);
    setIsRiggerCertificateUpload(null);
    setBiometricConsent(false);
    setFormData(INITIAL_FORM_DATA);
    setRadioSelection({ type: "RESET" });
  };

  const onSubmit = async (data) => {
    console.log("form", data);

    var hasError = false;
    const radioSelectionKeysToValidate = Object.keys(radioSelection).filter(
      (key) => !key.includes("Error")
    );

    radioSelectionKeysToValidate.forEach((key) => {
      // console.log("key", radioSelection[key]);

      if (!radioSelection[key]) {
        console.log("radioSelection", radioSelection[key]);
        hasError = true;
        setRadioSelection({
          type: "SET_ERROR",
          name: key,
          error: `Please select an option for ${key}`,
        });
      }
    });

    if (hasError && sheetIds.includes(ID)) {
      console.log("hasError", hasError);
      return;
    } else if (!isGender) {
      setGenderError("Please select a gender");
    } else if (!isIdentifyAs && sheetIds.includes(ID)) {
      setidentifyasError("Please select an option for Identify As");
    } else if (!isMinority && sheetIds.includes(ID)) {
      setminorityError("Please select an option for Minority");
    }

    // Check if the facility code is within the valid range
    // else if (
    //   data.lastCardNumber < MIN_MAX_VALUES.facilityCode.min ||
    //   data.lastCardNumber > MIN_MAX_VALUES.facilityCode.max
    // ) {
    //   setError("lastCardNumber", {
    //     type: "manual",
    //     message: `Last Card Number  must be between ${MIN_MAX_VALUES.facilityCode.min} and ${MIN_MAX_VALUES.facilityCode.max}.`,
    //   });
    //   // Stop further execution if facility code is invalid
    //   return;
    // } else if (
    //   data.firstCardNumber < MIN_MAX_VALUES.cardNumber.min ||
    //   data.firstCardNumber > MIN_MAX_VALUES.cardNumber.max
    // ) {
    //   setError("firstCardNumber", {
    //     type: "manual",
    //     message: `First Card Number  must be between ${MIN_MAX_VALUES.cardNumber.min} and ${MIN_MAX_VALUES.cardNumber.max}.`,
    //   });
    //   // Stop further execution if facility code is invalid
    //   return;
    // }

    // else if (!isFireWatch) {
    //   setfireWatchError("Please select an option for Fire Watch");
    // } else if (!isWelderCertified) {
    //   setwelderCertifiedError("Please select an option for Welder Certificate");
    // }
    else {
      setIsLoading(true);
      data.jobTitle = selectedJob;
      data.gender = isGender;
      data.identifyas = isIdentifyAs;
      data.fireWatchCard = isFireWatch;
      data.profileImageUpload = selectedFile;
      data.weldingCertificateUpload = isWeldingCertificate;
      data.SSTCardUpload = isSSTCardUpload;
      data.FireWatchCertificateUpload = isFireWatchCertificateUpload;
      data.ScaffoldCertificateUpload = isScaffoldCertificateUpload;
      data.ScissorLiftCertificateUpload = isScissorLiftCertificateUpload;
      data.RiggerCertificateUpload = isRiggerCertificateUpload;
      data.minority = isMinority;
      data.biometricConsent = biometricConsent;
      data.weldingCertificate = isWelderCertified;
      data.employer = radioSelection.employer;
      data.ethnicity = radioSelection.ethnicity;
      data.veteran = radioSelection.veteran;
      data.orientation = radioSelection.orientation;
      data.jobRole = radioSelection.jobRole;
      // let formattedIssueDate = new Date(data.sstIssueDate).toISOString().split('T')[0]; // Extracts the date part
      // let formattedExpireDate = new Date(data.sstExpireDate).toISOString().split('T')[0];
      // const combinedAddress = [
      //   data.streetAddress,
      //   data.unit,
      //   data.city,
      //   data.state,
      //   data.zipCode,
      // ]
      //   .filter(Boolean)
      //   .join(", ");

      const formDataForSpecialSheet = [
        { key: "sheetId", value: smartSheetData.sheetId },
        { key: "firstName", value: data.firstName },
        { key: "lastName", value: data.lastName },
        { key: "jobTitle", value: data.jobRole },
        { key: "phoneNumber", value: data.phoneNumber },
        { key: "emailAddress", value: data.email },
        { key: "contactList", value: data.email },
        { key: "trade", value: data.trade },
        { key: "employer", value: data.employer },
        { key: "ethnicity", value: data.ethnicity },
        { key: "veteran", value: data.veteran },
        { key: "jobRole", value: data.jobRole },
        { key: "homeAddress", value: data.homeaddress },
        { key: "gender", value: data.gender },
        { key: "identifyas", value: data.identifyas },
        { key: "minority", value: data.minority },
        data.sstIssueDate && {
          key: "sstIssueDate",
          value: format(new Date(data.sstIssueDate), "yyyy-MM-dd"),
        },

        // {
        //   key: sheetIds.includes(ID) ? "sstIssueDate" : "sstExpireDate",
        // value: format(
        //   new Date(
        //     sheetIds.includes(ID) ? data.sstIssueDate : data.sstExpireDate
        //   ),
        //   "yyyy-MM-dd"
        // ),
        // },
        { key: "orientation", value: data.orientation },
        { key: "emergencyContact", value: data.emergencyContact },
        { key: "emergencyContactPhone", value: data.emergencyContactPhone },
        { key: "doyouhaveaFireWatchCard", value: data.fireWatchCard || "No" },
        {
          key: "doyouhaveaWeldingCertificate",
          value: data.weldingCertificate || "No",
        },
        { key: "biometricConcent", value: data.biometricConsent },
        // Conditional fields
        data.profileImageUpload && {
          key: "profileImage",
          value: data.profileImageUpload,
        },
        data.weldingCertificateUpload && {
          key: "WelderCertificateIfapplicable",
          value: data.weldingCertificateUpload,
        },
        data.weldingCertificateUpload && {
          key: "WeldingCertificateIfapplicable",
          value: data.weldingCertificateUpload,
        },
        data.SSTCardUpload && {
          key: "SSTCard",
          value: data.SSTCardUpload,
        },
        data.FireWatchCertificateUpload && {
          key: "FireWatchCertificateIfapplicable",
          value: data.FireWatchCertificateUpload,
        },
        data.ScaffoldCertificateUpload && {
          key: "ScaffoldCertificateIfapplicable",
          value: data.ScaffoldCertificateUpload,
        },
        data.ScissorLiftCertificateUpload && {
          key: "ScissorLiftCertificateIfapplicable",
          value: data.ScissorLiftCertificateUpload,
        },
        data.RiggerCertificateUpload && {
          key: "RiggerCertificateIfapplicable",
          value: data.RiggerCertificateUpload,
        },
      ].filter(Boolean); // Filter out any undefined entries

      const formDataForDefaultSheets = [
        { key: "sheetId", value: smartSheetData.sheetId },
        { key: "firstName", value: data.firstName },
        { key: "lastName", value: data.lastName },
        // { key: "jobTitle", value: data.jobRole },
        { key: "phoneNumber", value: data.phoneNumber },
        { key: "emailAddress", value: data.email },
        { key: "contactList", value: data.email },
        // { key: "trade", value: data.trade },
        { key: "employer", value: data.employer },
        { key: "ethnicity", value: data.ethnicity },
        { key: "veteran", value: data.veteran },
        // { key: "jobRole", value: data.jobRole },
        { key: "homeAddress", value: data.homeaddress },
        { key: "gender", value: data.gender },
        // { key: "identifyas", value: data.identifyas },
        // { key: "minority", value: data.minority },
        data.sstIssueDate && {
          key: "sstIssueDate",
          value: format(new Date(data.sstIssueDate), "yyyy-MM-dd"),
        },
        // data.sstExpireDate && {
        //   key: "sstExpireDate",
        //   value: format(new Date(data.sstExpireDate), "yyyy-MM-dd"),
        // },
        // { key: "orientation", value: data.orientation },
        { key: "emergencyContactName", value: data.emergencyContact },
        { key: "emergencyContactNumber", value: data.emergencyContactPhone },
        { key: "doyouhaveaFireWatchCard", value: data.fireWatchCard || "No" },
        {
          key: "doyouhaveaWeldingCertificate",
          value: data.weldingCertificate || "No",
        },
        { key: "biometricConcent", value: data.biometricConsent },
        // Conditional fields
        data.profileImageUpload && {
          key: "profileImage",
          value: data.profileImageUpload,
        },
        data.weldingCertificateUpload && {
          key: "WeldingCertificateIfapplicable",
          value: data.weldingCertificateUpload,
        },
        data.SSTCardUpload && {
          key: "SSTCard",
          value: data.SSTCardUpload,
        },
        data.FireWatchCertificateUpload && {
          key: "FireWatchCertificate",
          value: data.FireWatchCertificateUpload,
        },
        data.ScaffoldCertificateUpload && {
          key: "ScaffoldCertificate",
          value: data.ScaffoldCertificateUpload,
        },
        data.ScissorLiftCertificateUpload && {
          key: "ScissorLiftCertificate",
          value: data.ScissorLiftCertificateUpload,
        },
        data.RiggerCertificateUpload && {
          key: "RiggerCertificate",
          value: data.RiggerCertificateUpload,
        },
      ].filter(Boolean);

      const formData = new FormData();

      const FieldsWithFormula = smartSheetData.sheetColumns.filter(
        (field) => field.formula
      );
      // console.log("FieldsWithFormula", FieldsWithFormula);
      const normalizeString = (str) =>
        str.replace(/\s+/g, " ").trim().toLowerCase();

      const formulaFieldTitles = FieldsWithFormula.map((field) =>
        field.title.replace(/\s/g, "").toLowerCase()
      );

      // Create a function to conditionally append data
      const appendToFormData = (key, value) => {
        // Normalize the key by removing extra spaces and converting to lowercase
        const normalizedKey = normalizeString(key);

        // Only append if the normalized key is not in formulaFieldTitles
        if (!formulaFieldTitles.includes(normalizedKey)) {
          // console.log("Appending:", "key:", key, "value:", value);
          formData.append(key, value);
        }
      };

      if (sheetIds.includes(ID)) {
        formDataForSpecialSheet.forEach(({ key, value }) =>
          appendToFormData(key, value)
        );
      } else {
        formDataForDefaultSheets.forEach(({ key, value }) => {
          console.log("Appending:", "key:", key, "value:", value);
          appendToFormData(key, value);
        });
      }
      // console.log("FieldsWithFormula", FieldsWithFormula);

      // formData.append("sheetId", smartSheetData.sheetId);

      // appendToFormData("firstName", data.firstName);
      // appendToFormData("lastName", data.lastName);
      // appendToFormData("jobTitle", data.jobRole);
      // appendToFormData("phoneNumber", data.phoneNumber);
      // appendToFormData("emailAddress", data.email);
      // appendToFormData("contactList", data.email);
      // appendToFormData("trade", data.trade);
      // // formData.append("hardHatNumber", data.hardhatNumber);
      // appendToFormData("employer", data.employer);
      // appendToFormData("ethnicity", data.ethnicity);
      // appendToFormData("veteran", data.veteran);
      // appendToFormData("jobRole", data.jobRole);
      // appendToFormData("homeAddress", data.homeaddress);
      // appendToFormData("gender", data.gender);
      // appendToFormData("identifyas", data.identifyas);
      // appendToFormData("minority", data.minority);
      // appendToFormData("sstEnableDate", data.sstIssueDate);
      // // appendToFormData("sstExpireDate",data.sstExpireDate);
      // // ID === specficSheetId||specficSheetId1

      // sheetIds.includes(ID)
      //   ? appendToFormData(
      //       "sstIssueDate",
      //       format(new Date(data.sstIssueDate), "yyyy-MM-dd")
      //     )
      //   : appendToFormData(
      //       "sstExpireDate",
      //       format(new Date(data.sstExpireDate), "yyyy-MM-dd")
      //     );
      // // appendToFormData("orientationDate", data.orientationDate);
      // appendToFormData("orientation", data.orientation);
      // // appendToFormData("orientation1YearExpire", data.orientation1YearExpire);
      // appendToFormData("emergencyContact", data.emergencyContact);
      // appendToFormData("emergencyContactPhone", data.emergencyContactPhone);
      // // formData.append("firstCardNumber", data.firstCardNumber);
      // // formData.append("lastCardNumber", data.lastCardNumber);
      // formData.append("doyouhaveaFireWatchCard", data.fireWatchCard || "No");
      // formData.append(
      //   "doyouhaveaWeldingCertificate",
      //   data.weldingCertificate || "No"
      // );
      // formData.append("biometricConcent", data.biometricConsent);

      // if (data.profileImageUpload) {
      //   formData.append("profileImage", data.profileImageUpload);
      // }
      // if (data.weldingCertificateUpload) {
      //   formData.append(
      //     "WelderCertificateIfapplicable",
      //     data.weldingCertificateUpload
      //   );
      // }
      // if (data.SSTCardUpload) {
      //   formData.append("SSTCard", data.SSTCardUpload);
      // }
      // if (data.FireWatchCertificateUpload) {
      //   formData.append(
      //     "FireWatchCertificateIfapplicable",
      //     data.FireWatchCertificateUpload
      //   );
      // }
      // if (data.ScaffoldCertificateUpload) {
      //   formData.append(
      //     "ScaffoldCertificateIfapplicable",
      //     data.ScaffoldCertificateUpload
      //   );
      // }
      // if (data.ScissorLiftCertificateUpload) {
      //   formData.append(
      //     "ScissorLiftCertificateIfapplicable",
      //     data.ScissorLiftCertificateUpload
      //   );
      // }
      // if (data.RiggerCertificateUpload) {
      //   formData.append(
      //     "RiggerCertificateIfapplicable",
      //     data.RiggerCertificateUpload
      //   );
      // }

      // if (data.fireWatchCard === "Yes" && data.fireWatchExpire) {
      //   // formData.append("firewatchExpireDate", data.fireWatchExpire);
      // }
      // if (data.weldingCertificate === "Yes" && data.weldingCertExpireDate) {
      //   // formData.append("welderExpireDate", data.weldingCertExpireDate);
      // }

      await axios
        .post(ADD_ROW_TO_SMARTSHEET, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          setIsLoading(false);
          if (response.data.status >= 200 && response.data.status < 300) {
            // Check if response.data is not empty
            if (response.data && Object.keys(response.data).length !== 0) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Form submitted successfully!",
              });
              resetForm();
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Form submission failed.",
              });
            }
          } else {
            console.log(response);
            Swal.fire({
              icon: "error",
              title: "Error",
              text:
                response?.data?.error?.message ||
                response?.data?.message ||
                "Please check the details and try again",
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
          let errorMessage;

          // Check if the error has a response property, indicating a server-side error
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            errorMessage =
              error.response.data.message || `Error: ${error.response.status}`;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage = "No response received from the server.";
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = "Request setup failed.";
          }

          Swal.fire({
            icon: "error",
            title: "Error",
            text: errorMessage,
          });
        });
      // resetForm();
    }
  };

  const handleUploadProfileImage = (e) => {
    const file = e.target.files[0];
    const maxSize = 4 * 1024 * 1024;

    if (file && file.size > maxSize) {
      setprofileImageSizeError(
        "File size exceeds 4 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setSelectedFile(null);
    } else {
      setprofileImageSizeError("");
      setSelectedFile(file);
    }
  };

  const handleDropImage = (e, uploadHandler) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    uploadHandler({ target: { files: files } });
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleUploadWeldingCertificate = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;

    if (file && file.size > maxSize) {
      setweldingCertificateError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsWeldingCertificate(null);
    } else {
      setweldingCertificateError("");
      setIsWeldingCertificate(file);
    }
  };

  const handleUploadFireWatchCertificate = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;

    if (file && file.size > maxSize) {
      setFireWatchCertificateSizeError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsFireWatchCertificateUpload(null);
    } else {
      setFireWatchCertificateSizeError("");
      setIsFireWatchCertificateUpload(file);
    }
  };
  const handleUploadSSTCard = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;

    if (file && file.size > maxSize) {
      setSSTCardSizeError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsSSTCardUpload(null);
    } else {
      setSSTCardSizeError("");
      setIsSSTCardUpload(file);
    }
  };
  const handleUploadScaffoldCertificate = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;

    if (file && file.size > maxSize) {
      setScaffoldCertificateSizeError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsScaffoldCertificateUpload(null);
    } else {
      setScaffoldCertificateSizeError("");
      setIsScaffoldCertificateUpload(file);
    }
  };
  const handleUploadScissorLiftCertificate = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (file && file.size > maxSize) {
      setScissorLiftCertificateSizeError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsScissorLiftCertificateUpload(null);
    } else {
      setScissorLiftCertificateSizeError("");
      setIsScissorLiftCertificateUpload(file);
    }
  };
  const handleUploadRiggerCertificate = (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (file && file.size > maxSize) {
      setRiggerCertificateSizeError(
        "File size exceeds 10 MB limit. Please select a smaller file."
      );
      e.target.value = null;
      setIsRiggerCertificateUpload(null);
    } else {
      setRiggerCertificateSizeError("");
      setIsRiggerCertificateUpload(file);
    }
  };

  return (
    <div
      className={`${
        sheetIds.includes(ID)
          ? styles.fromMainwrapper
          : styles.fromMainwrapperDefault
      }`}
    >
      <div className={`${styles.fromInnerwrapper}`}>
        {isLoading && <Loader />}
        <div className={`${styles.topSection} mt-3`}>
          <img className="mb-3" src={IMAGES.FormLogo} alt="logo" />
          <p className={`${styles.fontTitle}`} style={{ textTransform: 'uppercase' }}>
            <strong>{smartSheetData.title}</strong>
          </p>
          <p className={`${styles.fontTitle}`}>
            <strong>
              {sheetIds.includes(ID) ? (
                "Sanico USA"
              ) : (
                <>
                  {/* <span className={"employeeFormHeadingTop"}>{"Sanico USA "}</span> */}
                  <span style={{ color: "black" }}>Construction Access Onboarding</span>
                </>
              )}
            </strong>
          </p>
         
        </div>
        <section className={`${styles.formWrapper}`}>
          <div className={`${styles.topDis}`}>
            <h2 className={`${styles.AnsBlow} text-center mb-3`}>
              PLEASE ANSWER BELOW
            </h2>
            <div className={`${styles.textBox}`}>
              {/* <p className={`${styles.subText}`}> */}
              {/* <p className={`${styles.subText}`}>
                  <strong>
                    Welcome to {smartSheetData.siteName} Project located at{" "}
                    {smartSheetData.address}.
                  </strong>
                </p> */}
              <p className={`${styles.subText}`}>
                <strong>
                  Welcome to {smartSheetData.siteName} Project located at{" "}
                  {smartSheetData.address}.
                  {sheetIds.includes(ID)
                    ? "The following questions are needed to gain access onto the project."
                    : " To gain access to the project site, please answer the following required questions."}
                  {sheetIds.includes(ID)
                    ? " An ID card reader will be used on this site to gain access to this project"
                    : " Multiple ID badge card readers will be used for site access control throughout this project."}
                </strong>
              </p>
              {/* <p className={`${styles.subText}`}>
                  <strong>
                    {" "}
                    {sheetIds.includes(ID)?
                    " An ID card reader will be used on this site to gain access to this project":" Multiple ID badge card readers will be used for site access control throughout this project."
                    }
                    {" "}
                  </strong>
                </p> */}
              {/* </p> */}
            </div>
          </div>
          {/* //{ID === specficSheetId||specficSheetId1 */}
          {sheetIds.includes(ID) ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-fields">
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "First name is required",
                      // pattern: {
                      //   value: /^\d{6}$/,
                      //   message: "firstname must be a 6-digit number.",
                      // },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="First Name"
                          placeholder="First Name"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.firstName && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.firstName.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Last name is required",
                      // pattern: {
                      //   value: /^\d{6}$/,
                      //   message: "firstname must be a 6-digit number.",
                      // },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Last Name"
                          placeholder="Last Name"
                          name="lastName"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.lastName && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.lastName.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Phone Number{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Phone Number is required",
                      pattern: {
                        value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                        message:
                          "Please enter a valid phone number (+x xxx xxx xxxx).",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          // label="Phone"
                          // CustomIcon={<FaPhone />}
                          type="phone"
                          placeholder="+1 625 999 3488"
                          value={field.value}
                          index="phone"
                          maxLength={65}
                          handleChange={(e) => {
                            field.onChange(e);
                          }}
                          required
                        />
                        {errors.phoneNumber && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.phoneNumber.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      // required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="email"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Email Address"
                          placeholder="Email Address"
                          name="email"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.email && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.email.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={`${styles.topDis}`}>
                  <Controller
                    name="homeaddress"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Home Address is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="homeaddress"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Home Address"
                          placeholder="Home Address"
                          name="homeaddress"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.homeaddress && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.homeaddress.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="trade"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Trade is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Trade"
                          placeholder="Trade"
                          name="trade"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.trade && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.trade.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={`${styles.topDis} datee-picker-custom-input`}>
                  <Controller
                    name="sstIssueDate"
                    control={control}
                    rules={{
                      required: "SST Issue Date is required",
                    }}
                    render={({ field }) => (
                      <div
                        className={`${styles.topDis} datee-picker-custom-input`}
                      >
                        <label className={`${styles.labelStyle}`}>
                          SST Issue Date{" "}
                          <span className="fw-bold fs-6 text-danger">*</span>
                        </label>
                        <DatePicker
                          className={`${styles.modalInput} form-control`}
                          selected={field.value}
                          onChange={(date) => {
                            const formattedDate = formatDate(date);
                            field.onChange(formattedDate);
                          }}
                          dateFormat="MM/dd/yyyy"
                          showIcon
                          placeholderText="SST Issue Date"
                          icon={<SlCalender />}
                        />
                      </div>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="emergencyContact"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Emergency Contact Name"
                          placeholder="Emergency Contact"
                          name="emergencyContact"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.emergencyContact && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.emergencyContact.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="emergencyContactPhone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Emergency Contact Number"
                          placeholder="Emergency Contact Number"
                          name="emergencyContactPhone"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.emergencyContactPhone && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.emergencyContactPhone.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Gender <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {gender.map((genderOption) => (
                    <RadioBtn
                      key={genderOption}
                      title={genderOption}
                      name="gender"
                      id={`gender-${genderOption
                        .toLowerCase()
                        .replace(/\s/g, "")}`} // Append "gender-" prefix to make it unique
                      value={genderOption.toLowerCase().replace(/\s/g, "")}
                      checked={isGender === genderOption}
                      handleChange={() => handleGenderRadioChange(genderOption)}
                    />
                  ))}

                  {genderError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {genderError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Orientation{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {orientation.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="orientation"
                      id={`orientation-${option}`}
                      value={option}
                      checked={radioSelection.orientation === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.orientationError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.orientationError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Ethnicity{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {ethnicity.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="ethnicity"
                      id={`ethnicity-${option}`}
                      value={option}
                      checked={radioSelection.ethnicity === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.ethnicityError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.ethnicityError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Employer <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {employer.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="employer"
                      id={`employer-${option}`}
                      value={option}
                      checked={radioSelection.employer === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.employerError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.employerError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Veteran <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {veteran.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="veteran"
                      id={`veteran-${option}`} // Append "gender-" prefix to make it unique
                      value={option}
                      checked={radioSelection.veteran === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.veteranError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.veteranError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Job Role <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {jobRole.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="jobRole"
                      id={`jobRole-${option}`} // Append "gender-" prefix to make it unique
                      value={option}
                      checked={radioSelection.jobRole === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.jobRoleError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.jobRoleError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Identify as{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {identifyasoptions.map((identifyasOption) => (
                    <RadioBtn
                      key={identifyasOption}
                      title={identifyasOption}
                      name="identifyas"
                      id={`identifyas-${identifyasOption
                        .toLowerCase()
                        .replace(/\s/g, "")}`} // Append "identifyas-" prefix to make it unique
                      value={identifyasOption.toLowerCase().replace(/\s/g, "")}
                      checked={isIdentifyAs === identifyasOption}
                      handleChange={() =>
                        handleIdentifyAsRadioChange(identifyasOption)
                      }
                    />
                  ))}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Minority <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {minorityoptions.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="minority"
                      id={`minority-${option.toLowerCase().replace(/\s/g, "")}`} // Append "minority-" prefix to make it unique
                      value={option.toLowerCase()}
                      checked={isMinority === option}
                      handleChange={() => handleMinorityChange(option)}
                    />
                  ))}
                </div>
                <div className={`${styles.TopBorderWrapper} `}>
                  <h2 className={`${styles.AnsBlow}`}>UPLOAD THE FOLLOWING</h2>
                  <p className={`${styles.note}`}>
                    Note: File size should be less than 10 MB
                  </p>
                  <p className={`${styles.note}`}>
                    Note: file size for Profile Image should be less than 2 MB
                  </p>
                  <p className={`${styles.note}`}>
                    Note: Only (.png .jpg .gif) files are accepted
                  </p>
                  <ol className={`${styles.listUpload}`}>
                    <li className={`${styles.listItems}`}>Profile Image</li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadProfileImage)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="headshotUpload"
                      >
                        Profile Image Upload{" "}
                        <span className="fw-bold fs-6 text-danger">*</span>
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {selectedFile
                              ? selectedFile.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="profileImageUpload"
                          name="profileImageUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadProfileImage}
                          className={styles.fileInput}
                        />
                      </div>
                      {profileImageSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {profileImageSizeError}
                        </div>
                      )}
                    </div>

                    <li className={`${styles.listItems}`}>SST CARD</li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) => handleDropImage(e, handleUploadSSTCard)}
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="sstCardUpload"
                      >
                        SST Card Upload
                        <span className="fw-bold fs-6 text-danger"> *</span>
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isSSTCardUpload
                              ? isSSTCardUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="sstCardUpload"
                          name="sstCardUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadSSTCard}
                          className={styles.fileInput}
                        />
                      </div>
                      {setSSTCardSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {SSTCardSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Welding Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadWeldingCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="weldingCertificateUpload"
                      >
                        Welding Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isWeldingCertificate
                              ? isWeldingCertificate.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="weldingCertificateUpload"
                          name="weldingCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadWeldingCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {weldingCertificateError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {weldingCertificateError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Fire Watch Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadFireWatchCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="fireWatchCertificateUpload"
                      >
                        Fire Watch Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isFireWatchCertificateUpload
                              ? isFireWatchCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="fireWatchCertificateUpload"
                          name="fireWatchCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadFireWatchCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {FireWatchCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {FireWatchCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Scaffold Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadScaffoldCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="scaffoldCertificateUpload"
                      >
                        Scaffold Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isScaffoldCertificateUpload
                              ? isScaffoldCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="scaffoldCertificateUpload"
                          name="scaffoldCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadScaffoldCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {ScaffoldCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {ScaffoldCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Scissor Lift Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadScissorLiftCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="scissorLiftCertificateUpload"
                      >
                        Scissor Lift Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isScissorLiftCertificateUpload
                              ? isScissorLiftCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="scissorLiftCertificateUpload"
                          name="scissorLiftCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadScissorLiftCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {ScissorLiftCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {ScissorLiftCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Rigger Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadRiggerCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="riggerCertificateUpload"
                      >
                        Rigger Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isRiggerCertificateUpload
                              ? isRiggerCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="riggerCertificateUpload"
                          name="riggerCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadRiggerCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {RiggerCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {RiggerCertificateSizeError}
                        </div>
                      )}
                    </div>
                  </ol>
                </div>
                {smartSheetData.biometricEnabled && (
                  <>
                    <div className={`${styles.topDis} `}>
                      <h2 className={`${styles.AnsBlow}`}>
                        Copy of Sanico USA Biometric Policy
                      </h2>

                      <a
                        className={`${styles.linkItem}`}
                        href="https://www.sanicousa.com/biometric-privacy-policy"
                      >
                        https://www.sanicousa.com/biometric-privacy-policy
                      </a>
                    </div>
                    <div className={`${styles.topDis}`}>
                      <label className={`${styles.labelStyle}`}>
                        Biometric Concent
                      </label>
                      <p className={`${styles.FileText}`}>
                        Checking this box gives your consent to use Biometrics
                        based on the attached policy
                      </p>
                      <div>
                        <input
                          className={`${styles.checkBoxCus}`}
                          type="checkbox"
                          id="biometric"
                          name="biometricConsent"
                          checked={biometricConsent}
                          onChange={handleBiometricChange}
                        />{" "}
                      </div>
                    </div>
                  </>
                )}
                <div className={`${styles.TopBorderWrapper} `}>
                  <button
                    type="submit"
                    disabled={
                      !isGender ||
                      !isIdentifyAs ||
                      !isMinority ||
                      // !isFireWatch ||
                      // !isWelderCertified ||
                      !selectedFile ||
                      !isSSTCardUpload
                    }
                    className={`${styles.submitCustombtn} btn`}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-fields">
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "First name is required",
                      // pattern: {
                      //   value: /^\d{6}$/,
                      //   message: "firstname must be a 6-digit number.",
                      // },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="First Name"
                          placeholder="First Name"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.firstName && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.firstName.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Last name is required",
                      // pattern: {
                      //   value: /^\d{6}$/,
                      //   message: "firstname must be a 6-digit number.",
                      // },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Last Name"
                          placeholder="Last Name"
                          name="lastName"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.lastName && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.lastName.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Job Title{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {jobRole.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="jobRole"
                      id={`jobRole-${option}`} // Append "gender-" prefix to make it unique
                      value={option}
                      checked={radioSelection.jobRole === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.jobRoleError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.jobRoleError}
                    </div>
                  )}
                </div> */}
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Phone Number{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Phone Number is required",
                      pattern: {
                        value: /^\+\d{1,3}\s?\d{3}\s?\d{3}\s?\d{4}$/,
                        message:
                          "Please enter a valid phone number (+x xxx xxx xxxx).",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          // label="Phone"
                          // CustomIcon={<FaPhone />}
                          type="phone"
                          placeholder="+1 625 999 3488"
                          value={field.value}
                          index="phone"
                          maxLength={65}
                          handleChange={(e) => {
                            field.onChange(e);
                          }}
                          required
                        />
                        {errors.phoneNumber && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.phoneNumber.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      // required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="email"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Email Address"
                          placeholder="Email Address"
                          name="email"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.email && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.email.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="homeaddress"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Home Address is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="homeaddress"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Street Address,Unit,City,State,Zip Code"
                          placeholder="Address"
                          name="homeaddress"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.homeaddress && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.homeaddress.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                {/* <div className={`${styles.topDis}`}>
                  <Controller
                    name="streetAddress"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Street Address is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Street Address"
                          placeholder="Street Address"
                          name="streetAddress"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.streetAddress && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.streetAddress.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="unit"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Home Address is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Unit"
                          placeholder="Unit"
                          name="unit"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.homeaddress && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.homeaddress.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="city"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "City is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="City"
                          placeholder="City"
                          name="city"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.city && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.city.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="state"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "State is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="State"
                          placeholder="State"
                          name="state"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.state && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.state.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="zipCode"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Zip Code is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Zip Code"
                          placeholder="Zip Code"
                          name="zipCode"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.zipCode && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.zipCode.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div> */}

                {/* //Trade */}
                {/* <div className={`${styles.topDis}`}>
                  <Controller
                    name="trade"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Trade is required",
                    }}
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Trade"
                          placeholder="Trade"
                          name="trade"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.trade && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.trade.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div> */}

                <div className={`${styles.topDis}`}>
                  <Controller
                    name="emergencyContact"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Emergency Contact Name"
                          placeholder="Emergency Contact"
                          name="emergencyContact"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.emergencyContact && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.emergencyContact.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className={`${styles.topDis}`}>
                  <Controller
                    name="emergencyContactPhone"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <InputFields
                          type="text"
                          inputStyle={styles.modalInput}
                          labelStyle={styles.labelStyle}
                          label="Emergency Contact Number"
                          placeholder="Emergency Contact Number"
                          name="emergencyContactPhone"
                          value={field.value}
                          handleChange={(e) => {
                            field.onChange(e.target.value);
                          }}
                          required
                        />
                        {errors.emergencyContactPhone && (
                          <div
                            className={`${styles.validationmessage} validation-message`}
                          >
                            {errors.emergencyContactPhone.message}
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Gender <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {gender.map((genderOption) => (
                    <RadioBtn
                      key={genderOption}
                      title={genderOption}
                      name="gender"
                      id={`gender-${genderOption
                        .toLowerCase()
                        .replace(/\s/g, "")}`} // Append "gender-" prefix to make it unique
                      value={genderOption.toLowerCase().replace(/\s/g, "")}
                      checked={isGender === genderOption}
                      handleChange={() => handleGenderRadioChange(genderOption)}
                    />
                  ))}

                  {genderError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {genderError}
                    </div>
                  )}
                </div>
                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Orientation{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {orientation.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="orientation"
                      id={`orientation-${option}`}
                      value={option}
                      checked={radioSelection.orientation === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.orientationError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.orientationError}
                    </div>
                  )}
                </div> */}
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Ethnicity{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {ethnicity.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="ethnicity"
                      id={`ethnicity-${option}`}
                      value={option}
                      checked={radioSelection.ethnicity === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.ethnicityError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.ethnicityError}
                    </div>
                  )}
                </div>
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Veteran <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {veteran.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="veteran"
                      id={`veteran-${option}`} // Append "gender-" prefix to make it unique
                      value={option}
                      checked={radioSelection.veteran === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.veteranError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.veteranError}
                    </div>
                  )}
                </div>
                {employer.length > 0 && (
                  <div className={`${styles.topDis}`}>
                    <label className={`${styles.labelStyle}`}>
                      Employer{" "}
                      <span className="fw-bold fs-6 text-danger">*</span>
                    </label>

                    <select
                      name="employer"
                      id="employer"
                      value={radioSelection.employer}
                      onChange={handleOptionsRadioChange}
                      className="form-select"
                    >
                      <option value="">Select an employer</option>
                      {employer.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    {radioSelection.employerError && (
                      <div
                        className={`${styles.validationmessage} validation-message`}
                      >
                        {radioSelection.employerError}
                      </div>
                    )}
                  </div>
                )}

                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Employer <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {employer.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="employer"
                      id={`employer-${option}`}
                      value={option}
                      checked={radioSelection.employer === option}
                      handleChange={handleOptionsRadioChange}
                    />
                  ))}

                  {radioSelection.employerError && (
                    <div
                      className={`${styles.validationmessage} validation-message`}
                    >
                      {radioSelection.employerError}
                    </div>
                  )}
                </div> */}

                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Identify as{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {identifyasoptions.map((identifyasOption) => (
                    <RadioBtn
                      key={identifyasOption}
                      title={identifyasOption}
                      name="identifyas"
                      id={`identifyas-${identifyasOption
                        .toLowerCase()
                        .replace(/\s/g, "")}`} // Append "identifyas-" prefix to make it unique
                      value={identifyasOption.toLowerCase().replace(/\s/g, "")}
                      checked={isIdentifyAs === identifyasOption}
                      handleChange={() =>
                        handleIdentifyAsRadioChange(identifyasOption)
                      }
                    />
                  ))}
                </div> */}
                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Minority <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {minorityoptions.map((option) => (
                    <RadioBtn
                      key={option}
                      title={option}
                      name="minority"
                      id={`minority-${option.toLowerCase().replace(/\s/g, "")}`} // Append "minority-" prefix to make it unique
                      value={option.toLowerCase()}
                      checked={isMinority === option}
                      handleChange={() => handleMinorityChange(option)}
                    />
                  ))}
                </div> */}

                {/* <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Do you have a Fire Watch Card{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {fireWatchCard.map((fireWatchCardOptions) => (
                    <RadioBtn
                      key={fireWatchCardOptions}
                      title={fireWatchCardOptions}
                      name="fireWatchCard"
                      id={`fireWatchCard-${fireWatchCardOptions
                        .toLowerCase()
                        .replace(/\s/g, "")}`}
                      value={fireWatchCardOptions
                        .toLowerCase()
                        .replace(/\s/g, "")}
                      checked={isFireWatch === fireWatchCardOptions}
                      handleChange={() =>
                        handleFireWatchRadioChange(fireWatchCardOptions)
                      }
                    />
                  ))}
                </div>
  
                {showDatePicker && (
                  <div className={`${styles.topDis}`}>
                    <Controller
                      name="fireWatchExpire"
                      control={control}
                      rules={{
                        required: "Firewatch expire date is required",
                      }}
                      render={({ field }) => (
                        <div
                          className={`${styles.topDis} datee-picker-custom-input`}
                        >
                          <label className={`${styles.labelStyle}`}>
                            Firewatch Expire{" "}
                            <span className="fw-bold fs-6 text-danger">*</span>
                          </label>
                          <DatePicker
                            className={`${styles.modalInput} form-control`}
                            selected={field.value} // Use field.value for the selected date
                            onChange={(date) => {
                              const formattedDate = formatDate(date);
                              field.onChange(formattedDate);
                            }}
                            dateFormat="MM/dd/yyyy"
                            showIcon
                            placeholderText="Firewatch expire"
                            icon={<SlCalender />}
                          />
                        </div>
                      )}
                    />
                  </div>
                )}
                <div className={`${styles.topDis}`}>
                  <label className={`${styles.labelStyle}`}>
                    Do you have a Welding Certificate{" "}
                    <span className="fw-bold fs-6 text-danger">*</span>
                  </label>
                  {weldingCertificateOptions.map((weldingCertificateOption) => (
                    <RadioBtn
                      key={weldingCertificateOption}
                      title={weldingCertificateOption}
                      name="weldingCertificate"
                      id={`weldingCertificate-${weldingCertificateOption
                        .toLowerCase()
                        .replace(/\s/g, "")}`}
                      value={weldingCertificateOption.toLowerCase()}
                      checked={isWelderCertified === weldingCertificateOption}
                      handleChange={() =>
                        handleWeldingCertificationChange(weldingCertificateOption)
                      }
                    />
                  ))}
                </div>
  
                {showWelderDatePicker && (
                  <div className={`${styles.topDis}`}>
                    <Controller
                      name="weldingCertExpireDate"
                      control={control}
                      rules={{
                        required:
                          "Welding certificate expiration date is required",
                      }}
                      render={({ field }) => (
                        <div
                          className={`${styles.topDis} datee-picker-custom-input`}
                        >
                          <label className={`${styles.labelStyle}`}>
                            Welding Certificate Expire{" "}
                            <span className="fw-bold fs-6 text-danger">*</span>
                          </label>
                          <DatePicker
                            className={`${styles.modalInput} form-control`}
                            selected={field.value}
                            onChange={(date) => {
                              const formattedDate = formatDate(date);
                              field.onChange(formattedDate);
                            }}
                            dateFormat="MM/dd/yyyy"
                            showIcon
                            placeholderText="Welder expire"
                            icon={<SlCalender />}
                          />
                        </div>
                      )}
                    />
                  </div>
                )} */}
                <div className={`${styles.TopBorderWrapper} `}>
                  <h2 className={`${styles.AnsBlow}`}>UPLOAD THE FOLLOWING</h2>
                  <p className={`${styles.note}`}>
                  <strong>Note:</strong> File size should be less than 10 MB
                  </p>
                  <p className={`${styles.note}`}>
                  <strong>Note:</strong> file size for Profile Image should be less than 4 MB
                  </p>
                  <p className={`${styles.note}`}>
                  <strong>Note:</strong> Only (.png .jpg .gif) files are accepted
                  </p>
                  <p className={`${styles.note}`}>
                        <strong>Note:</strong>Please upload a profile photo for your badge. Photo must be with plain background, not hats or sunglasses.
                    </p>
                  <ol className={`${styles.listUpload}`}>
                    <li className={`${styles.listItems}`}>Profile Image</li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadProfileImage)
                      }
                      onDragOver={handleDragOver}
                    >
                      
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="headshotUpload"
                      >
                        Profile Image Upload{" "}
                        <span className="fw-bold fs-6 text-danger">*</span>
                      </label>
                     
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {selectedFile
                              ? selectedFile.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="profileImageUpload"
                          name="profileImageUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadProfileImage}
                          className={styles.fileInput}
                        />
                      </div>
                      {profileImageSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {profileImageSizeError}
                        </div>
                      )}
                    </div>

                    <li className={`${styles.listItems}`}>SST CARD</li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) => handleDropImage(e, handleUploadSSTCard)}
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="sstCardUpload"
                      >
                        SST Card Upload
                        <span className="fw-bold fs-6 text-danger">
                          {sheetIds.includes(ID) ? "*" : ""}{" "}
                        </span>
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isSSTCardUpload
                              ? isSSTCardUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="sstCardUpload"
                          name="sstCardUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadSSTCard}
                          className={styles.fileInput}
                        />
                      </div>
                      {setSSTCardSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {SSTCardSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>SST Issue Date</li>
                    <div
                      className={`${styles.topDis} datee-picker-custom-input mb-3`}
                    >
                      <Controller
                        name="sstIssueDate"
                        control={control}
                        // rules={{
                        //   required: "SST Issue Date is required",
                        // }}
                        render={({ field }) => (
                          <div
                            className={`${styles.topDis} datee-picker-custom-input`}
                          >
                            <label className={`${styles.labelStyle}`}>
                              SST Issue Date{" "}
                              {/* <span className="fw-bold fs-6 text-danger">*</span> */}
                            </label>
                            <DatePicker
                              className={`${styles.modalInput} form-control`}
                              selected={field.value}
                              onChange={(date) => {
                                const formattedDate = formatDate(date);
                                field.onChange(formattedDate);
                              }}
                              dateFormat="yyyy/MM/dd"
                              showIcon
                              placeholderText="SST Issue Date"
                              icon={<SlCalender />}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <li className={`${styles.listItems}`}>
                      Welding Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadWeldingCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="weldingCertificateUpload"
                      >
                        Welding Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isWeldingCertificate
                              ? isWeldingCertificate.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="weldingCertificateUpload"
                          name="weldingCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadWeldingCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {weldingCertificateError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {weldingCertificateError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Fire Watch Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadFireWatchCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="fireWatchCertificateUpload"
                      >
                        Fire Watch Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isFireWatchCertificateUpload
                              ? isFireWatchCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="fireWatchCertificateUpload"
                          name="fireWatchCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadFireWatchCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {FireWatchCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {FireWatchCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Scaffold Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadScaffoldCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="scaffoldCertificateUpload"
                      >
                        Scaffold Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isScaffoldCertificateUpload
                              ? isScaffoldCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="scaffoldCertificateUpload"
                          name="scaffoldCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadScaffoldCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {ScaffoldCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {ScaffoldCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Scissor Lift Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadScissorLiftCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="scissorLiftCertificateUpload"
                      >
                        Scissor Lift Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isScissorLiftCertificateUpload
                              ? isScissorLiftCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="scissorLiftCertificateUpload"
                          name="scissorLiftCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadScissorLiftCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {ScissorLiftCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {ScissorLiftCertificateSizeError}
                        </div>
                      )}
                    </div>
                    <li className={`${styles.listItems}`}>
                      Rigger Certificate (If Applicable)
                    </li>
                    <div
                      className={`${styles.topDis}`}
                      onDrop={(e) =>
                        handleDropImage(e, handleUploadRiggerCertificate)
                      }
                      onDragOver={handleDragOver}
                    >
                      <label
                        className={`${styles.labelStyle}`}
                        htmlFor="riggerCertificateUpload"
                      >
                        Rigger Certificate Upload
                      </label>
                      <div className={styles.fileUploadbox}>
                        <div className={`${styles.fileUploadText}`}>
                          <span className={`${styles.fileText}`}>
                            {isRiggerCertificateUpload
                              ? isRiggerCertificateUpload.name
                              : "Drag and drop files here or"}
                            <button tabindex="0" type="button">
                              <span> browse files</span>
                            </button>
                          </span>
                        </div>
                        <input
                          tabindex="0"
                          type="file"
                          id="riggerCertificateUpload"
                          name="riggerCertificateUpload"
                          accept=".png, .jpg, .gif"
                          onChange={handleUploadRiggerCertificate}
                          className={styles.fileInput}
                        />
                      </div>
                      {RiggerCertificateSizeError && (
                        <div
                          className={`${styles.validationmessage} validation-message`}
                        >
                          {RiggerCertificateSizeError}
                        </div>
                      )}
                    </div>
                  </ol>
                </div>
                {smartSheetData.biometricEnabled && (
                  <>
                    <div className={`${styles.topDis} `}>
                      <h2 className={`${styles.AnsBlow}`}>
                        Copy of Sanico USA Biometric Policy
                      </h2>

                      <a
                        className={`${styles.linkItem}`}
                        href="https://www.sanicousa.com/biometric-privacy-policy"
                      >
                        https://www.sanicousa.com/biometric-privacy-policy
                      </a>
                    </div>
                    {/* <div className={`${styles.topDis}`}>
                      <label className={`${styles.labelStyle}`}>
                        Biometric Concent
                      </label>
                      <p className={`${styles.FileText}`}>
                        Checking this box gives your consent to use Biometrics
                        based on the attached policy
                      </p>
                      <div>
                        <input
                          className={`${styles.checkBoxCus}`}
                          type="checkbox"
                          id="biometric"
                          name="biometricConsent"
                          checked={biometricConsent}
                          onChange={handleBiometricChange}
                        />{" "}
                      </div>
                    </div> */}
                  </>
                )}
                <div className={`${styles.TopBorderWrapper} `}>
                  {/* <div className="pt-3">
                    <input
                      className={`${styles.checkBoxCus}`}
                      type="checkbox"
                      id="responses"
                      name="responses"
                      value=""
                    />
                    <label className="ps-2" for="">
                      Send me a copy of my responses
                    </label>
                  </div> */}
                  <button
                    type="submit"
                    disabled={
                      !isGender ||
                      !radioSelection.ethnicity ||
                      !radioSelection.veteran ||
                      !radioSelection.employer ||
                      // !isMinority ||
                      // !isFireWatch ||
                      // !isWelderCertified ||
                      (sheetIds.includes(ID)
                        ? !isIdentifyAs ||
                          !isMinority ||
                          !selectedFile ||
                          !isSSTCardUpload
                        : !selectedFile)
                    }
                    className={`${styles.submitCustombtn} btn`}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </form>
          )}
        </section>
      </div>
    </div>
  );
};

export default SnicoUsaForm;

//      {/* <div className={`${styles.topDis}`}>
//   <label className={`${styles.labelStyle}`}>
//     Job Title <span className="fw-bold fs-6 text-danger">*</span>
//   </label>
//   {jobTitles.map((title) => (
//     <RadioBtn
//       key={title}
//       title={title}
//       name="job"
//       id={title.toLowerCase().replace(/\s/g, "")}
//       value={title.toLowerCase().replace(/\s/g, "")}
//       checked={selectedJob === title}
//       handleChange={() => handleRadioChange(title)}
//     />
//   ))}
// </div> */}
//     {/* { <div className={`${styles.topDis}`}>
//   <Controller
//     name="contactList"
//     control={control}
//     defaultValue=""
//     rules={{
//       // required: "Email is required",
//       pattern: {
//         value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
//         message: "Invalid email address",
//       },
//     }}
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="email"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Contact List"
//           placeholder="Contact List"
//           name="contactList"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.contactList && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.contactList.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> } */}

//      {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="employer"
//     control={control}
//     defaultValue=""
//     rules={{
//       required: "Employer is required",
//       // pattern: {
//       //   value: /^\d{6}$/,
//       //   message: "firstname must be a 6-digit number.",
//       // },
//     }}
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Employer"
//           placeholder="Employer"
//           name="employer"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.employer && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.employer.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="veteranr"
//     control={control}
//     defaultValue=""
//     rules={{
//       required: "Veteran is required",
//     }}
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Veteran"
//           placeholder="Veteran"
//           name="veteran"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.veteran && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.veteran.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="ethnicity"
//     control={control}
//     defaultValue=""
//     rules={{
//       required: "Ethnicity is required",
//     }}
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Ethnicity"
//           placeholder="Ethnicity"
//           name="ethnicity"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.ethnicity && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.ethnicity.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="orientation"
//     control={control}
//     defaultValue=""
//     rules={{
//       required: "Orientation is required",
//     }}
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Orientation"
//           placeholder="Orientation"
//           name="orientation"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.orientation && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.orientation.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis} datee-picker-custom-input`}>
//   <Controller
//     name="orientationDate"
//     control={control}
//     rules={{
//       required: " Orientation Date is required",
//     }}
//     render={({ field }) => (
//       <div
//         className={`${styles.topDis} datee-picker-custom-input`}
//       >
//         <label className={`${styles.labelStyle}`}>
//           Orientation Date{" "}
//           <span className="fw-bold fs-6 text-danger">*</span>
//         </label>
//         <DatePicker
//           className={`${styles.modalInput} form-control`}
//           selected={field.value}
//           onChange={(date) => {
//             const formattedDate = formatDate(date);
//             field.onChange(formattedDate);
//           }}
//           dateFormat="MM/dd/yyyy"
//           showIcon
//           placeholderText="Orientation Date"
//           icon={<SlCalender />}
//         />
//       </div>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis} datee-picker-custom-input`}>
//   <Controller
//     name="orientation1YearExpire"
//     control={control}
//     rules={{
//       required: "Orientation Expire Date is required",
//     }}
//     render={({ field }) => (
//       <div
//         className={`${styles.topDis} datee-picker-custom-input`}
//       >
//         <label className={`${styles.labelStyle}`}>
//           Orientation Expire Date{" "}
//           <span className="fw-bold fs-6 text-danger">*</span>
//         </label>
//         <DatePicker
//           className={`${styles.modalInput} form-control`}
//           selected={field.value}
//           onChange={(date) => {
//             const formattedDate = formatDate(date);
//             field.onChange(formattedDate);
//           }}
//           dateFormat="MM/dd/yyyy"
//           showIcon
//           placeholderText="Orientation Expire Date"
//           icon={<SlCalender />}
//         />
//       </div>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="hardhatNumber"
//     control={control}
//     defaultValue=""
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Hardhat Number"
//           placeholder="Hardhat Number"
//           name="hardhatNumber"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.hardhatNumber && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.hardhatNumber.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}
//   {/* <div className={`${styles.topDis}`}>
//   <Controller
//     name="firstCardNumber"
//     control={control}
//     defaultValue=""
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="First Card Number"
//           placeholder="First Card Number"
//           name="firstCardNumber"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.firstCardNumber && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.firstCardNumber.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div>
// <div className={`${styles.topDis}`}>
//   <Controller
//     name="lastCardNumber"
//     control={control}
//     defaultValue=""
//     render={({ field }) => (
//       <>
//         <InputFields
//           type="text"
//           inputStyle={styles.modalInput}
//           labelStyle={styles.labelStyle}
//           label="Last Card Number"
//           placeholder="Last Card Number"
//           name="lastCardNumber"
//           value={field.value}
//           handleChange={(e) => {
//             field.onChange(e.target.value);
//           }}
//           required
//         />
//         {errors.lastCardNumber && (
//           <div
//             className={`${styles.validationmessage} validation-message`}
//           >
//             {errors.lastCardNumber.message}
//           </div>
//         )}
//       </>
//     )}
//   />
// </div> */}

//   {/* <div className={`${styles.topDis}`}>
//   <label className={`${styles.labelStyle}`}>
//     Gender <span className="fw-bold fs-6 text-danger">*</span>
//   </label>
//   {gender.map((genderOption) => (
//     <RadioBtn
//       key={genderOption}
//       title={genderOption}
//       name="gender"
//       id={`gender-${genderOption
//         .toLowerCase()
//         .replace(/\s/g, "")}`} // Append "gender-" prefix to make it unique
//       value={genderOption.toLowerCase().replace(/\s/g, "")}
//       checked={isGender === genderOption}
//       handleChange={() => handleGenderRadioChange(genderOption)}
//     />
//   ))}

//   {genderError && (
//     <div
//       className={`${styles.validationmessage} validation-message`}
//     >
//       {genderError}
//     </div>
//   )}
// </div> */}

//   {/* <div className={`${styles.topDis}`}>
//   <label className={`${styles.labelStyle}`}>
//     Do you have a Fire Watch Card{" "}
//     <span className="fw-bold fs-6 text-danger">*</span>
//   </label>
//   {fireWatchCard.map((fireWatchCardOptions) => (
//     <RadioBtn
//       key={fireWatchCardOptions}
//       title={fireWatchCardOptions}
//       name="fireWatchCard"
//       id={`fireWatchCard-${fireWatchCardOptions
//         .toLowerCase()
//         .replace(/\s/g, "")}`}
//       value={fireWatchCardOptions
//         .toLowerCase()
//         .replace(/\s/g, "")}
//       checked={isFireWatch === fireWatchCardOptions}
//       handleChange={() =>
//         handleFireWatchRadioChange(fireWatchCardOptions)
//       }
//     />
//   ))}
// </div>

// {showDatePicker && (
//   <div className={`${styles.topDis}`}>
//     <Controller
//       name="fireWatchExpire"
//       control={control}
//       rules={{
//         required: "Firewatch expire date is required",
//       }}
//       render={({ field }) => (
//         <div
//           className={`${styles.topDis} datee-picker-custom-input`}
//         >
//           <label className={`${styles.labelStyle}`}>
//             Firewatch Expire{" "}
//             <span className="fw-bold fs-6 text-danger">*</span>
//           </label>
//           <DatePicker
//             className={`${styles.modalInput} form-control`}
//             selected={field.value} // Use field.value for the selected date
//             onChange={(date) => {
//               const formattedDate = formatDate(date);
//               field.onChange(formattedDate);
//             }}
//             dateFormat="MM/dd/yyyy"
//             showIcon
//             placeholderText="Firewatch expire"
//             icon={<SlCalender />}
//           />
//         </div>
//       )}
//     />
//   </div>
// )}
// <div className={`${styles.topDis}`}>
//   <label className={`${styles.labelStyle}`}>
//     Do you have a Welding Certificate{" "}
//     <span className="fw-bold fs-6 text-danger">*</span>
//   </label>
//   {weldingCertificateOptions.map((weldingCertificateOption) => (
//     <RadioBtn
//       key={weldingCertificateOption}
//       title={weldingCertificateOption}
//       name="weldingCertificate"
//       id={`weldingCertificate-${weldingCertificateOption
//         .toLowerCase()
//         .replace(/\s/g, "")}`}
//       value={weldingCertificateOption.toLowerCase()}
//       checked={isWelderCertified === weldingCertificateOption}
//       handleChange={() =>
//         handleWeldingCertificationChange(weldingCertificateOption)
//       }
//     />
//   ))}
// </div>

// {showWelderDatePicker && (
//   <div className={`${styles.topDis}`}>
//     <Controller
//       name="weldingCertExpireDate"
//       control={control}
//       rules={{
//         required:
//           "Welding certificate expiration date is required",
//       }}
//       render={({ field }) => (
//         <div
//           className={`${styles.topDis} datee-picker-custom-input`}
//         >
//           <label className={`${styles.labelStyle}`}>
//             Welding Certificate Expire{" "}
//             <span className="fw-bold fs-6 text-danger">*</span>
//           </label>
//           <DatePicker
//             className={`${styles.modalInput} form-control`}
//             selected={field.value}
//             onChange={(date) => {
//               const formattedDate = formatDate(date);
//               field.onChange(formattedDate);
//             }}
//             dateFormat="MM/dd/yyyy"
//             showIcon
//             placeholderText="Welder expire"
//             icon={<SlCalender />}
//           />
//         </div>
//       )}
//     />
//   </div>
// )} */}

//     {/* <div className="pt-3">
//     <input
//       className={`${styles.checkBoxCus}`}
//       type="checkbox"
//       id="responses"
//       name="responses"
//       value=""
//     />
//     <label className="ps-2" for="">
//       Send me a copy of my responses
//     </label>
//   </div> */}
