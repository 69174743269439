import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import { IMAGES } from "../../assets/images/images";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "../Common/Loader/Loader";
import { BASE_URL, LOGIN } from "../../services/URL";
import { SANICO_USA_EMPLOYEE_FORM } from "../../Constant/Index";

const Login = () => {
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const showPassword = () => {
    setShowPass(!showPass);
  };
  useEffect(() => {
    const isValidEmail = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    const validatedEmail = isValidEmail(email);
    const isPasswordValid = password.length >= 3;

    setIsFormValid(validatedEmail && isPasswordValid);
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isEmailValid = (email) => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
    if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email address");
      return;
    }
    if (!email || !password) {
      setErrorMsg("Please enter both Email and Password");
      return;
    }

    if (password.length < 3) {
      setPasswordError("Password is too short");
      return;
    }
    try {
      setIsLoading(true);

      const userType = email === "admin@safescan.com" ? "admin" : "site admin";
      const response = await axios.post(LOGIN, {
        email,
        password,
        type: userType,
      });

      if (response.data.status === 200) {
        const token = response.data.data.accessToken;
        const userRole = response.data.data.user.type;
        const siteId = response.data.data.user.siteId;
        const companyId = response.data.data.user.companyId;
        const siteName = response.data.data.user.siteName;

        localStorage.setItem("token", token);
        localStorage.setItem("companyId", companyId);
        localStorage.setItem("siteName", siteName);
        localStorage.setItem(
          "user",
          JSON.stringify(response.data.data.user._id)
        );
        localStorage.setItem("type", userRole);
        localStorage.setItem("siteId", siteId);

        setIsLoading(false);
        setEmail("");
        setPassword("");

        if (userRole === "admin" || userRole === "site admin") {
          navigate("/smartsheets");
        } else {
          Swal.fire({
            icon: "error",
            text: "Please use Admin credentials!",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } else {
        setIsLoading(false);
        Swal.fire({
          icon: "error",
          text: response.data.error[0],
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.error(error);
      setErrorMsg("Invalid credentials. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div className={`${styles.loginPageWrapper}`}>
      <div className={styles.leftSide}>
        <img src={IMAGES.safeLogo} alt="logo" />
        <p className={styles.presentedByText}>By Sanico USA</p>
      </div>
      <div className={styles.rightSide}>
        <h1 className={styles.titleHeading}>Sign In</h1>
        <form className={styles.formWrapper} onSubmit={handleSubmit}>
          <div className="input-wrapper mb-3">
            <label>Email:</label>
            <InputGroup size="lg">
              <InputGroup.Text id="inputGroup-sizing-lg">
                <img src={IMAGES.emailIcon} alt="email icon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
            <span className="text-danger">{emailError}</span>
          </div>
          <div className="input-wrapper mb-3 position-relative">
            <label>Password:</label>
            <InputGroup size="lg">
              <InputGroup.Text id="inputGroup-sizing-lg">
                <img src={IMAGES.keyIcon} alt="password icon" />
              </InputGroup.Text>
              <Form.Control
                aria-label="Large"
                aria-describedby="inputGroup-sizing-sm"
                placeholder="Password"
                type={showPass ? "text" : "password"}
                value={password}
                className="pe-4"
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
            <span onClick={showPassword} className={styles.passIconWrapper}>
              {showPass ? (
                <AiOutlineEyeInvisible
                  style={{ color: "#1D1E2C4D", fontSize: "20px" }}
                />
              ) : (
                <AiOutlineEye
                  style={{ color: "#1D1E2C4D", fontSize: "20px" }}
                />
              )}
            </span>
            <span className="text-danger">{passwordError}</span>
          </div>
          {errorMsg && <div className="text-danger mb-3">{errorMsg}</div>}
          <div
            key={`default-checkbox`}
            className="mb-3 input-wrapper d-flex align-items-center justify-content-between"
          >
            <Form.Check
              type="checkbox"
              id={`default-checkbox`}
              label="Remember me"
            />
            <div>
              <small>
                {" "}
                <Link
                  className="text-black text-decoration-underline"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </small>
              <small className="ps-3">
                {" "}
                <Link
                  className="text-black text-decoration-underline"
                  to="/sanico-usa-employee-form"
                >
                  Employee Form
                </Link>
              </small>
            </div>
          </div>

          <button
            type="submit"
            className={`${styles.btnSignin} signInBtn`}
            // disabled={!isFormValid}
          >
            Sign In
          </button>
        </form>
      </div>
      {isLoading && <Loader />}
    </div>
  );
};

export default Login;
