// export const BASE_URL = "http://secure-scan.live/api/";
export const BASE_URL = "https://secure-scan.live/api/";
// export const BASE_URL = "http://safescan.nexteksol.com/api/";
// export const BASE_URL = "http://localhost:3000/api/";
export const LOGIN = `${BASE_URL}auth/login`;
export const LOGOUT = `${BASE_URL}auth/logout`;
// export const SIGNUP = `${BASE_URL}auth/signup`;
export const SIGNUP = `${BASE_URL}auth/adduser`;
// export const GET_USERS = `${BASE_URL}auth/getuser`;
export const GET_USERS = `${BASE_URL}auth/getuser`;
export const GET_SITES_ADMIN = `${BASE_URL}auth/getsitesadmin`;
export const UPDATE_PROFILE = `${BASE_URL}auth/updateprofile`;
export const ACTIVE_INACTIVE = `${BASE_URL}auth/getapprove`;
export const DELETE_USER = `${BASE_URL}auth/deleteuser`;
export const SEARCH_USER = `${BASE_URL}auth/search`;
export const SEARCH_ADMINS = `${BASE_URL}auth/searchadmin`;
export const RESET_CREDENTIALS = `${BASE_URL}auth/resetcredentials`;
export const CONNECT_SMARTSHEET = `${BASE_URL}auth/connectsmartsheet`;
export const ADD_FORM_DETAILS = `${BASE_URL}auth/addformdetails`;
export const UPDATE_SMARTSHEET = `${BASE_URL}auth/updatesmartsheetwebhook`;
export const ADD_ROW_TO_SMARTSHEET = `${BASE_URL}auth/addsmartsheetrow`;
export const GET_ALL_FORM_SETTING_LIST = `${BASE_URL}auth/getallformsetting`;
export const GET_FORM_SETTING_LIST = `${BASE_URL}auth/getformsetting`;
export const ADD_NEW_FORM_SETTING = `${BASE_URL}auth/formsetting`;
export const DELETE_FORM_SETTING = `${BASE_URL}auth/deleteformsetting`;
export const UPDATE_NEW_FORM_SETTING = `${BASE_URL}auth/formsetting`;

export const GET_SITES = `${BASE_URL}brivo/sites`;
// export const DASHBOARD_STATS = `${BASE_URL}brivo/stats`;

export const SEARCH_ALL_EMPLOYEES = `${BASE_URL}brivo/searchallemployees`;
export const EVENTS_HISTORY = `${BASE_URL}brivo/events_history`;
export const FETCH_USER_DETAILS = `${BASE_URL}brivo/brivo_userdetails`;
export const GET_ALL_EMPLOYEES = `${BASE_URL}brivo/getsitesemployees`;
export const DASHBOARD_STATS = `${BASE_URL}brivo/dashboardstats`;
export const REGISTER_COMPANY = `${BASE_URL}brivo/registercompany`;
export const GET_COMPANIES = `${BASE_URL}brivo/getcompanies`;
export const UPDATE_COMPANY = `${BASE_URL}brivo/updatecompany`;
export const DELETE_COMPANY = `${BASE_URL}brivo/deletecompany`;
export const SEARCH_COMPANY = `${BASE_URL}brivo/searchcompany`;

export const GET_SMART_SHEET_FORM_DETAILS = `${BASE_URL}getSmartSheetFormDetails`;
